<template>
	<div>
		<v-bottom-sheet v-model="$parent.editApiUser" inset>
			<v-sheet class="rounded-sheet" height="392px" style="overflow-y: auto !important;">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-7">
						<v-icon left color="black">mdi-pencil-circle</v-icon> Edit Api User
					</v-card-title>

					<v-form @submit.prevent="editApiUser()">
						<v-row>
							<v-col cols="12">
								<v-text-field type="text" label="Name" outlined hide-details v-model="editApiUserData.name"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field type="text" label="Remaining Requests" outlined hide-details v-model="editApiUserData.remaining_requests"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field type="text" label="Monthly Requests" outlined hide-details v-model="editApiUserData.monthly_requests"></v-text-field>
							</v-col>
						</v-row>

						<v-btn type="submit" class="mx-auto mt-6 d-block px-10" color="primary white--text" depressed :loading="editApiUserLoading" :disabled="!isDataFilled">Edit</v-btn>
					</v-form>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "EditApiUser",
	data: () => ({
		editApiUserLoading: false,
		editApiUserData: {
			name: '',
			remaining_requests: '',
			monthly_requests: '',
		}
	}),
	computed: {
		isDataFilled() {
			return true
		},
		isEditApiUserOpen() {
			return this.$parent.editApiUser;
		}
	},
	watch: {
		isEditApiUserOpen(val) {
			if (val) {
				this.editApiUserData.id = this.$parent.editApiUserData.id;
				this.editApiUserData.name = this.$parent.editApiUserData.name;
				this.editApiUserData.remaining_requests = this.$parent.editApiUserData.remaining_requests;
				this.editApiUserData.monthly_requests = this.$parent.editApiUserData.monthly_requests;
			}
		}
	},
	methods: {
		editApiUser() {
			this.editApiUserLoading = true;

			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("apiUser_id", this.editApiUserData.id);
			data.append("name", this.editApiUserData.name);
			data.append("remaining_requests", this.editApiUserData.remaining_requests);
			data.append("monthly_requests", this.editApiUserData.monthly_requests);


			/* make call to server */
			this.$http.post("admin/apiUsers/updateApiUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.editApiUser = false;
						this.$parent.getApiUsers();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.editApiUserLoading = false;
				})
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style>