<template>
	<div>
		<v-app-bar app :class="(isMobile) ? 'primary white--text' : 'blue lighten-3'">

			<v-app-bar-nav-icon :class="(isMobile) ? 'white--text' : ''" @click.stop="showMenu = !showMenu"></v-app-bar-nav-icon>

			<v-spacer></v-spacer>

			<div>
				<v-menu :close-on-content-click="false" min-width="300px" rounded offset-y nudge-bottom="20">
					<template v-slot:activator="{ on }">
						<div v-on="on" style="cursor: pointer;">
							<v-avatar color="white" size="35">
								<img src="../assets/pics/user.png">
							</v-avatar>
							<v-icon class="mr-2" style="color: inherit !important;">mdi-chevron-down</v-icon>
						</div>
					</template> 
					<v-card>
						<v-list-item-content class="justify-center">
							<div class="mx-auto text-center mt-10">
								<v-avatar color="white" size="100">
									<img src="../assets/pics/user.png">
								</v-avatar>

								<h3 class="mt-4">{{ this.$store.state.Admin.admin_name }}</h3>

								<v-btn class="my-8" depressed rounded outlined color="red darken-4" to="/logout">
									Logout from Account
								</v-btn>
							</div>
						</v-list-item-content>
					</v-card>
				</v-menu>
			</div>

		</v-app-bar>

		<v-navigation-drawer color="primary" v-model="showMenu" app floating :permanent="permanentMenu">

			<v-list dark>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="pr-3">
							<h4>Admin Panel</h4>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<div class="pr-3">
				<router-link class="sidebar-button" v-for="item in items" :key="item.title" :to="item.href" active-class="active">
					<div class="sidebar-button-icon ml-2">
						<v-icon style="color: inherit;">{{ item.icon }}</v-icon>
					</div>

					<div class="sidebar-button-title">
						{{ item.title }}
					</div>
				</router-link>
			</div>

		</v-navigation-drawer>
	</div>
</template>

<script>
export default {
	name: "Header",
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		permanentMenu() {
			if (this.$vuetify.breakpoint.smAndDown) return false;
			return this.showMenu;
		},
	},
	data: () => ({
		showMenu: true,
		windowWidth: window.innerWidth,
		items: [
			{ title: "Properties", icon: "mdi-currency-eur", href: "/panel/properties" },
			{ title: "Api Users", icon: "mdi-account-group", href: "/panel/api-users" },
			{ title: "Settings", icon: "mdi-cog", href: "/panel/settings" },
		],
	}),
	created() {
		if (this.windowWidth <= 960) this.showMenu = false;
	}
};
</script>

<style lang="scss">
.sidebar-button {
	display: flex;
	color: white !important;
	padding: 10px;
	text-decoration: none !important;
	border-bottom-right-radius: 30px;
	border-top-right-radius: 30px;
	margin-bottom: 5px;
	transition: background-color .3s cubic-bezier(.25,.8,.5,1);

	&.active {
		background: #ffffff !important;
		color: #1976d2 !important;
	}

	&:hover {
		background: #ffffff20;
	}

	.sidebar-button-icon {
		width: 42px;
		margin-right: 7px;
		color: inherit !important;
	}
}

.navbar i {
	color: inherit !important;
}
</style>