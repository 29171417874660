import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login'
import Logout from '../views/Logout'
import Panel from '../views/Panel'

import Properties from '../views/properties//Properties'
import ApiUsers from '../views/api-users/ApiUsers'
import Settings from '../views/settings/Settings'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/panel',
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Logout
	},
	{
		path: '/panel',
		name: 'Panel',
		redirect: '/panel/properties',
		component: Panel,
		children: [
			{
				path: 'properties',
				name: 'Properties',
				component: Properties
			},
			{
				path: 'api-users',
				name: 'ApiUsers',
				component: ApiUsers
			},
			{
				path: 'settings',
				name: 'Settings',
				component: Settings
			},
		]
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
