<template>
	<div>
		<v-bottom-sheet v-model="$parent.editProperty" inset>
			<v-sheet class="rounded-sheet" height="472px" style="overflow-y: auto !important;">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-7">
						<v-icon left color="black">mdi-pencil-circle</v-icon> Edit Property
					</v-card-title>

					<v-form @submit.prevent="editProperty()">
						<v-row>
							<v-col cols="12">
								<v-text-field type="text" label="Image" outlined hide-details v-model="editPropertyData.img"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field type="text" label="Short Name" outlined hide-details v-model="editPropertyData.short_name"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field type="text" label="Name" outlined hide-details v-model="editPropertyData.name"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field type="tel" label="Constant Percentage (Buy)" outlined hide-details v-model="editPropertyData.constant_percentage_buy"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field type="tel" label="Constant Percentage (Sell)" outlined hide-details v-model="editPropertyData.constant_percentage_sell"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field type="tel" label="Constant Amount (Buy)" outlined hide-details v-model="editPropertyData.constant_amount_buy"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field type="tel" label="Constant Amount (Sell)" outlined hide-details v-model="editPropertyData.constant_amount_sell"></v-text-field>
							</v-col>
						</v-row>

						<v-btn type="submit" class="mx-auto mt-6 d-block px-10" color="primary white--text" depressed :loading="editPropertyLoading" :disabled="!isDataFilled">Edit</v-btn>
					</v-form>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "EditProperty",
	data: () => ({
		editPropertyLoading: false,
		editPropertyData: {
			img: '',
			short_name: '',
			name: '',
			constant_percentage_buy: '',
			constant_percentage_sell: '',
			constant_amount_buy: '',
			constant_amount_sell: ''
		}
	}),
	computed: {
		isDataFilled() {
			return true
		},
		isEditPropertyOpen() {
			return this.$parent.editProperty;
		}
	},
	watch: {
		isEditPropertyOpen(val) {
			if (val) {
				this.editPropertyData.img = this.$parent.editPropertyData.img;
				this.editPropertyData.short_name = this.$parent.editPropertyData.short_name;
				this.editPropertyData.name = this.$parent.editPropertyData.name;
				this.editPropertyData.constant_percentage_buy = this.$parent.editPropertyData.constant_percentage_buy;
				this.editPropertyData.constant_percentage_sell = this.$parent.editPropertyData.constant_percentage_sell;
				this.editPropertyData.constant_amount_buy = this.$parent.editPropertyData.constant_amount_buy;
				this.editPropertyData.constant_amount_sell = this.$parent.editPropertyData.constant_amount_sell;
			}
		}
	},
	methods: {
		editProperty() {
			this.editPropertyLoading = true;

			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("property_id", this.$parent.editPropertyData.id);

			/* make data */
			let changed_data = {};
			Object.keys(this.editPropertyData).forEach(key => {
				var k = key;
				var val = this.editPropertyData[key];

				if (val != '' && this.$parent.editPropertyData[key] != val) {
					data.append(k, val);
				}
			});
			

			/* make call to server */
			this.$http.post("admin/properties/updateProperty", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.editProperty = false;
						this.$parent.getProperties();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.editPropertyLoading = false;
				})
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style>