<template>
	<div>
		<v-bottom-sheet v-model="$parent.deleteApiUser" inset>
			<v-sheet class="rounded-sheet" height="230px">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-3 red--text text--darken-4">
						<v-icon right color="red darken-4">mdi-delete</v-icon> Delete Api User
					</v-card-title>

					<v-card-text>
						Are you sure you want to delete this Api User?
					</v-card-text>

					<v-btn class="mt-2" color="red darken-4 white--text" block depressed @click="deleteApiUser()" :loading="deleteApiUserLoading">Delete Api User</v-btn>
					<v-btn class="mt-4" color="grey darken-4" block outlined @click="$parent.deleteApiUser = false">Nevermind</v-btn>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "DeleteApiUser",
	data: () => ({
		deleteApiUserLoading: false,
	}),
	methods: {
		deleteApiUser() {
			this.deleteApiUserLoading = true;

			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("apiUser_id", this.$parent.deleteApiUserId);


			/* make call to server */
			this.$http.post("admin/apiUsers/deleteApiUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.deleteApiUser = false;
						this.$parent.getApiUsers();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.deleteApiUserLoading = false;
				})
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style> 