<template>
	<div>
		<v-bottom-sheet v-model="$parent.addApiUser" inset>
			<v-sheet class="rounded-sheet" height="312px" style="overflow-y: auto !important;">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-7">
						<v-icon left color="black">mdi-plus</v-icon> Add Api User
					</v-card-title>

					<v-form @submit.prevent="addApiUser()">
						<v-row>
							<v-col cols="12">
								<v-text-field type="text" label="Name" outlined hide-details v-model="addApiUserData.name"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field type="text" label="Monthly Requests" outlined hide-details v-model="addApiUserData.monthly_requests"></v-text-field>
							</v-col>
						</v-row>

						<v-btn type="submit" class="mx-auto mt-6 d-block px-10" color="primary white--text" depressed :loading="addApiUserLoading" :disabled="!isDataFilled">Add</v-btn>
					</v-form>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "AddApiUser",
	data: () => ({
		addApiUserLoading: false,
		addApiUserData: {
			name: '',
			remaining_requests: '',
			monthly_requests: '1000',
		}
	}),
	computed: {
		isDataFilled() {
			return true
		},
		isAddApiUserOpen() {
			return this.$parent.addApiUser;
		}
	},
	watch: {
		isAddApiUserOpen(val) {
			
		}
	},
	methods: {
		addApiUser() {
			this.addApiUserLoading = true;

			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("name", this.addApiUserData.name);
			data.append("monthly_requests", this.addApiUserData.monthly_requests);

			/* make call to server */
			this.$http.post("admin/apiUsers/addApiUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.addApiUser = false;
						this.$parent.getApiUsers();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.addApiUserLoading = false;
				})
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style>